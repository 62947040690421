<template>
    <v-navigation-drawer v-model="$store.state.auth.drawer" fixed app :clipped="$store.state.auth.clipped" :width="270">
        <v-list>
            <v-list-item>
            <v-list-item-avatar>
                <v-img src="@/assets/images/users.png" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{fullname}}</v-list-item-title>
                <v-list-item-subtitle>{{branchname}}</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <div class="bg-gray-200 w-100 h-7 font-bold">ເມນູຫລັກ</div>
        <v-list  style="margin-top: -8px">
            <template v-for="(rows, i) in listmenu">
                <template v-if="rows.menu_name !== ''">
                    <v-subheader :key="i">{{ rows.menu_name }}</v-subheader>
                </template>

                <template >
                    <v-list-item 
                        v-for="(items, index) in rows.menu_sub"
                        :key="i + '_' + index" 
                        :to="{ name: items.menu_url }"
                        :inactive="false"
                        router
                        exact
                        color="amber lighten-1" 
                        class=" black--text"
                        active-class="amber lighten-1 black--text"
                    >
                        <v-list-item-action>
                            <v-icon>{{ items.menu_icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title v-text="items.menu_name" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>


<script>
    export default {
        data() {
            return {
                selectedItem: 1,
                statusSelected: false,
                showMenu2: false,
                fullname: '',
                branchname: '',
                listmenu: [],
                statusItems: [
                    {
                    menuId: 1,
                    menuName: "ໜ້າຫຼັກ",
                    menuUrl: "/",
                    menuLevel: 0,
                    parent: 1,
                    menuIcon: "mdi-apps",
                    menuImg: null,
                    subMenus: [],
                    },
                    {
                    menuId: 2,
                    menuName: "ອັບໂຫລດເອກະສານ",
                    menuUrl: "/login",
                    menuLevel: 0,
                    parent: 2,
                    menuIcon: "mdi-progress-upload",
                    menuImg: null,
                    subMenus: [],
                    },
                    {
                    menuId: 3,
                    menuName: "ເອກະສານລໍຖ້າທ່ານເຊັນ",
                    menuUrl: "/",
                    menuLevel: 0,
                    parent: 3,
                    menuIcon: "mdi-progress-clock",
                    menuImg: null,
                    subMenus: [],
                    },
                    {
                    menuId: 5,
                    menuName: "ຕິດຕາມເອກະສານຕົນເອງ",
                    menuUrl: "/doctrack",
                    menuLevel: 0,
                    parent: 5,
                    menuIcon: "mdi-book-search-outline",
                    menuImg: null,
                    subMenus: [
                        {
                        menuId: 51,
                        menuName: "ເອກະສານທີ່ເຊັນແລ້ວ",
                        menuUrl: "/doctrack/alreadysign",
                        menuLevel: 1,
                        parent: 5,
                        menuIcon: "mdi-draw-pen",
                        menuImg: null,
                        subMenus: [],
                        color: "success",
                        },
                        {
                        menuId: 52,
                        menuName: "ເອກະສານທີ່ຍັງລໍຖ້າເຊັນ",
                        menuUrl: "/doctrack/waitsign",
                        menuLevel: 1,
                        parent: 5,
                        menuIcon: "mdi-account-clock-outline",
                        menuImg: null,
                        subMenus: [],
                        color: "warning",
                        },
                        {
                        menuId: 53,
                        menuName: "ເອກະສານທີ່ຖືກ Reject",
                        menuUrl: "/doctrack/rejectdoc",
                        menuLevel: 1,
                        parent: 5,
                        menuIcon: "mdi-information-outline",
                        menuImg: null,
                        subMenus: [],
                        color: "error",
                        },
                    ],
                    },
                    {
                    menuId: 6,
                    menuName: "ລາຍງານ",
                    menuUrl: "/report",
                    menuLevel: 0,
                    parent: 6,
                    menuIcon: "mdi-file-chart-outline",
                    menuImg: null,
                    subMenus: [],
                    },
                    {
                    menuId: 7,
                    menuName: "ຈັດການຂໍ້ມູນ + ສິດ",
                    menuUrl: "/urole",
                    menuLevel: 0,
                    parent: 7,
                    menuIcon: "mdi-account-cog-outline",
                    menuImg: null,
                    subMenus: [
                        {
                        menuId: 71,
                        menuName: "ຈັດການສິດທິຜູ້ໃຊ້ລະບົບ",
                        menuUrl: "/urole/usermapping",
                        menuLevel: 1,
                        parent: 7,
                        menuIcon: "mdi-account-star-outline",
                        menuImg: null,
                        subMenus: [],
                        color: "blue",
                        },
                    ],
                    },
                ],
            };
        },
        async mounted() {
            this.fullname = await this.$store.state.auth.fullname;
            this.branchname = await this.$store.state.auth.branchname;
            await this.getListMenu();
        },
        methods: {
            async getListMenu(){
                this.listmenu = await [];
                await this.$store.commit("auth/setLoading", true);
                const result = await this.$store.dispatch("auth/getListMenu");
                await this.$store.commit("auth/setLoading", false);
                if(result.success){
                    this.listmenu = await result.data;
                }
            },
            async goToPage(route){
                console.log(route)
            }
        },
    };
</script>

<style>
    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
        margin-right: 10px !important;
    }
</style>