<template>
    <div>
        <Navbar />
        <Sidebar />
        <v-main>
            <v-container fluid>
                <router-view/>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar.vue'
    import Sidebar from '@/components/Sidebar.vue'
    export default {
        components:{
            Navbar,
            Sidebar,
        },
    }
</script>

