<template>
    <v-app-bar app fixed :clipped-left="$store.state.auth.clipped" color="amber lighten-1">
        <v-app-bar-nav-icon
            @click.stop="$store.state.auth.drawer = !$store.state.auth.drawer"
            class="white--back"
        />
        <v-toolbar-title
            class="white--back d-none d-md-block d-lg-block d-xl-block"
            v-text="'ລະບົບຕິດຕາມການຂົນສົ່ງ ບໍລິສັດ ກັນລະຍາ ນຳເຂົ້າ ແລະ ສົ່ງອອກ ຈຳກັດຜູ້ດຽວ'"
        />
        <v-spacer />
        <v-btn icon class="white--back">
            <v-icon>mdi-key-variant</v-icon>
        </v-btn>
        <v-btn icon class="white--back">
            <v-icon v-if="notification == 0">mdi-bell</v-icon>
            <v-badge overlap :content="notification" color="red" v-else>
                <v-icon>mdi-bell</v-icon>
            </v-badge>
        </v-btn>
        <v-btn text class="white--back" @click="logout()">
            <v-icon>mdi-logout</v-icon>
            <span class="d-md-block d-none ">ອອກຈາກລະບົບ</span>
        </v-btn>
    </v-app-bar>
</template>

<script>
    export default {
        data(){
            return {
                notification: 0
            }
        },
        methods:{
            logout(){
                this.$swal.fire({
                    title: 'ອອກຈາກລະບົບ',
                    text: 'ທ່ານຕ້ອງການອອກຈາກລະບົບ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline" ></i> ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline" ></i> ຍົກເລີກ',
                    confirmButtonColor: '#28a745',
                    cancelButtonColor: '#dc3545',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push({name: 'login'})
                    }
                })
            }
        }
    }
</script>